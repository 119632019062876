@import "../../../assets/styles/_dimensions";
@import "../../../assets/styles/_colors";

.backButton {
    cursor: pointer;
    display: flex;
    color: $cor-contraste-5;
    width: max-content;
    user-select: none;
    img {
        margin-right: $spacing-2x;
    }
    :hover {
        filter: brightness(1.25);
    }
}
