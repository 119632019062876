@import "../../../assets/styles/dimensions";
@import "../../../assets/styles/colors";

.form-field-control {
    width: 100%;
    // min-width: 290px;

    > .label {
        display: block;
        margin-bottom: $spacing-1x;
    }

    .field-wrapper {
        display: flex;
        width: 100%;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .omni-radio-check {
        display: flex;

        flex-wrap: wrap;
        justify-content: space-between;

        &.vertical {
            flex-direction: column;
            align-items: flex-start;
        }

        .option {
            display: flex;
            margin-right: $spacing-5x;
            padding: $spacing-2x 0;

            .input {
                width: auto;
                margin-right: $spacing-2x;
            }
        }
    }

    .input {
        outline: none;
        border: none;
        border-bottom: 1px solid $cor-contraste-5;
        padding: $spacing-2x;
        width: 100%;

        &.input-error {
            border-bottom: 1px solid red;
        }

        &.omni-select {
            padding: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            padding: $spacing-2x;
            background-image: url("../../../assets/images/down-select-icon.png");
            background-position: right;
            background-position-x: 95%;
            background-repeat: no-repeat;

            &::-ms-expand {
                display: none;
            }
        }
    }

    .error {
        color: red;
        margin: 0 $spacing-2x;
    }
}
