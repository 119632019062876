@import "../../../assets/styles/colors";
@import "../../../assets/styles/dimensions";

.filtro-card {
    .header {
        padding: $spacing-4x $spacing-6x;

        .titulo {
            font-weight: bold;
            font-size: 1.2em;
            margin-left: $spacing-3x;
        }
    }

    .filtros-wrapper {
        margin: $spacing-3x $spacing-3x 0 $spacing-3x;
        padding-bottom: $spacing-3x;
        overflow: auto;

        .filtros {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $spacing-3x;

            .acoes {
                display: flex;
                justify-content: flex-end;

                button[type="submit"] {
                    width: 150px;
                }
            }

            .span-2 {
                grid-column: span 2;
            }
        }
    }
}
