@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.btn-primary {
    background-color: $cor-acento-1;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: $cor-acento-1, $amount: 5);
    }
}
