@import "../../assets/styles/colors";
@import "../../assets/styles/dimensions";

.card {
    padding: $spacing-6x;
    border: 1px solid $cor-contraste-6;
    border-radius: 4px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.04);
    background-color: $cor-contraste-3;
    .header {
        display: flex;
        align-items: center;
        .icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .info {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: $spacing-4x;

            p {
                margin: 0;
            }

            .title {
                font-size: 18px;
            }

            .value {
                margin-top: $spacing-2x;
                font-weight: 600;
                font-size: 24px;
                letter-spacing: 0.01em;
            }
        }
    }
}

.card-inline {
    padding: $spacing-2x $spacing-8x $spacing-2x $spacing-4x;
    border: 1px solid $cor-contraste-6;
    border-radius: 4px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.04);
    background-color: $cor-contraste-3;

    .header {
        display: flex;
        align-items: center;
        .icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 11px;
            }
        }

        .info {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
            flex: 1;
            font-size: 16px;

            p {
                margin: 0;
            }

            .value {
                font-weight: 600;
                margin: 0 $spacing-2x;
            }
        }
    }
}
