@import "~@omnijus/common/build/assets/styles/_colors";
@import "~@omnijus/common/build/assets/styles/_dimensions";

.respostas-revisao {
    table-layout: fixed;
    border-collapse: collapse;

    colgroup {
        :first-child {
            width: 50%;
        }
    }

    th {
        padding: $spacing-4x;
        background-color: $cor-contraste-2;
        text-align: start;
        font-size: 1.5em;
    }

    tbody {
        .header-row {
            font-weight: bold;
        }
        .offset-row {
            td {
                &:first-child {
                    padding-left: $spacing-8x;
                }
            }
        }
        .row-divergente {
            background-color: lighten($color: #ff0000, $amount: 45%);
        }
    }

    td {
        padding: $spacing-2x;
        border-top: 1px solid $cor-contraste-6;
    }
}

.no-padding {
    padding: 0 !important;
}
