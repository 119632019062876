@import "../../assets/styles/_colors";
@import "../../assets/styles/_dimensions";

.campo {
    display: flex;
    flex-direction: column;
    gap: $spacing-2x;

    .label {
        font-weight: bold;
        margin: 0;
    }

    .value {
        margin: 0;
    }
}
