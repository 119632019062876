.rotulo {
    padding: 0.4rem 1rem;
    color: white;
    border-radius: 15px;
    width: fit-content;
    display: inline-block;
    margin-right: 0.4rem;
    cursor: default;
    &.compacto {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        * {
            display: none;
        }
    }
}
