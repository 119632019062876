@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.edicao-audiencia {
    .erro-informacoes {
        text-align: center;
    }

    .cards-wrapper {
        > :not(:first-child) {
            margin-top: $spacing-3x;
        }

        .enderecamento,
        .principal,
        .dados-parceiro {
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            overflow-x: auto;

            .item {
                padding: $spacing-2x;
                list-style: none;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: $spacing-1x;
        }
    }
}

.erros {
    display: flex;
    flex-direction: column;

    .item {
        display: flex;
        gap: $spacing-4x;

        .label {
            font-weight: bold;
        }

        .value {
            text-align: justify;
        }
    }
}
