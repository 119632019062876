@import "../../../assets/styles/colors";
@import "../../../assets/styles/dimensions";

.btn-outlined {
    background-color: $cor-contraste-3;
    border-radius: 5px;
    padding: $spacing-2x $spacing-4x;
    border: 2px solid $cor-acento-3;
    color: $cor-acento-3;
    font-weight: bold;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: $cor-acento-3;
        color: $cor-contraste-3;
    }

    &:disabled {
        cursor: default;
        background-color: $cor-contraste-3;
        color: $cor-contraste-2;
        border: 2px solid $cor-contraste-10;
    }
}
