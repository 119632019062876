@import "../../assets/styles/dimensions";
@import "../../assets/styles/colors";

.row-detalhe-processo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0;
    padding: $spacing-1x;

    > .item,
    > .row-group {
        border-bottom: 1px solid $cor-contraste-9;
        padding: $spacing-2x;
    }

    .item {
        .label {
            margin-right: $spacing-3x;
            color: $cor-acento-6;

            &::after {
                content: ":";
            }
        }
    }

    .row-group {
        .title {
            color: $cor-acento-6;
        }

        .group {
            list-style: none;
            padding-left: $spacing-3x;
        }
    }
}
