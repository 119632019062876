@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.criar-audiencia {
    .cards-wrapper {
        >:not(:first-child) {
            margin-top: $spacing-3x;
        }
    }

    .grid-campos {
        display: grid;
        gap: $spacing-3x;
        align-items: flex-start;
    }

    .grid-campos-enderecamento {
        grid-template-columns: repeat(5, 1fr);
    }

    .grid-campos-audiencia {
        grid-template-columns: repeat(4, 1fr);
        
        textarea {
            min-height: 100px;
            resize: none;
        }
    }

    .grid-campos-parceiro {
        grid-template-columns: repeat(3, 1fr);
        
        textarea {
            min-height: 100px;
            resize: none;
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: $spacing-1x;
    }
}

.erros {
    display: flex;
    flex-direction: column;

    .item {
        display: flex;
        gap: $spacing-4x;

        .label {
            font-weight: bold;
        }

        .value {
            text-align: justify;
        }
    }
}