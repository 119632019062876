@import "../../assets/styles/_colors";
@import "../../assets/styles/_dimensions";

.agendamentos {
    > :not(:first-child) {
        margin-top: $spacing-3x;
    }
}

.barraAcao {
    display: flex;
    margin-top: $spacing-4x;
    margin-bottom: $spacing-4x;
    justify-content: flex-end;
}

.botoesAcao {
    > * {
        margin-left: 1rem;
    }

    .botaoAcao {
        min-width: max-content;
        max-height: 3rem;
        outline: none;
        text-decoration: none;
        border: 2px solid $cor-acento-3;
        border-radius: 3px;
        padding: $spacing-2x $spacing-3x;
        background-color: $cor-contraste-3;
        color: $cor-acento-3;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;
            color: $cor-contraste-3;
        }
    }
}

.marginTop {
    margin-top: 1rem;
}

.marginTop2 {
    margin-top: 2rem;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
}

.marginRight {
    margin-right: 1rem;
}

.modalCriarAgendamentos {
    display: grid;
    grid-template-columns: repeat(3, 4fr);
    gap: 1rem;
}

.criarAgendamentos {
    display: flex;
    gap: 1rem;
    > * {
        flex: 1;
    }
}

.cabecalho-processo {
    .card {
        padding-top: $spacing-4x;
        padding-bottom: $spacing-4x;
        .header {
            margin-bottom: $spacing-4x;
            .icon {
                width: 40px;
                height: 40px;
                img {
                    transform: scale(0.6667);
                }
            }
        }
        .title {
            font-weight: 600;
            font-size: 16px;
        }
        hr {
            height: 0;
            border: 1px solid $cor-contraste-6;
            margin-left: -$spacing-6x;
            margin-right: -$spacing-6x;
            margin-bottom: 0;
        }
    }

    .linha {
        display: flex;
        flex-flow: row wrap;
        .campo {
            flex: 1;
        }
    }

    .campo {
        padding-top: $spacing-3x;
        padding-bottom: $spacing-3x;
        border-bottom: 1px solid $cor-contraste-6;
        .chave {
            font-weight: 600;
            font-size: 14px;
            display: inline-block;
            width: 10em;
        }
        .valor {
            display: inline-block;
        }
    }

    .linha:last-child {
        .campo {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

.classe-processo {
    font-weight: 400;
    font-size: 1.1rem;
}

.trashIcon {
    path {
        fill: red;
    }
}

.botaoSalvar {
    background-color: #4caf50;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #4caf50, $amount: 5);
    }
}

.undoIcon {
    fill: white;
}

.trashIconDisabled {
    path {
        fill: grey;
    }
}
