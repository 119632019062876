.linhaForm {
    display: flex;
    gap: 1rem;
    > div {
        flex: 1;
    }
}

.trashIcon {
    margin-top: 2rem;
}

.botoes {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1rem;
    .botaoAdicionarMovimento {
        &:hover {
            background: #0000ff9c;
            color: white;
            border-color: transparent;
        }

        background: white;
        color: blue;
        border: 1px solid blue;
    }
}

.listaNovosMovimentos {
    overflow-y: auto;
    max-height: 60vh;
    padding-right: 1rem;
}
