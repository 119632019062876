.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.movimentosProcesso {
    max-height: 30rem;
}
