@import "~@omnijus/common/build/assets/styles/_colors";
@import "~@omnijus/common/build/assets/styles/_dimensions";

.listarPeticionamentos {
    > * {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}

.mensagemErro {
    width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.deleteButton {
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:disabled {
        cursor: default;
        .deleteIcon {
            path {
                fill: grey;
            }
        }
    }

    .deleteIcon {
        path {
            fill: red;
        }
    }
}

.marginTop {
    margin-top: 1rem;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
}

.marginRight {
    margin-right: 1rem;
}

.modalDocumentosInfo {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.modalDataPrevistaInfo {
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    //gap: 1rem;
}

.barraAcao {
    display: flex;
    margin-top: $spacing-4x;
    margin-bottom: $spacing-4x;
    justify-content: flex-end;
}

.botoesAcao {
    > * {
        margin-left: 1rem;
    }

    .botaoAcao {
        min-width: max-content;
        max-height: 3rem;
        outline: none;
        text-decoration: none;
        border: 2px solid $cor-acento-3;
        border-radius: 3px;
        padding: $spacing-2x $spacing-3x;
        background-color: $cor-contraste-3;
        color: $cor-acento-3;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $cor-acento-3;
            color: $cor-contraste-3;
        }
    }
}

.modalIncluirDocumentos {
    > * {
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
}

.trashIcon {
    path {
        fill: red;
    }
}

.formIncluirDocumentos {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 6fr);
}

.criarPeticionamento {
    > * {
        margin-top: 1rem;
    }

    .form {
        display: grid;
        grid-template-columns: repeat(3, 4fr);
        gap: 1rem;
    }
}

.formBuscarDetalhesProcesso {
    display: grid;
    grid-template-columns: repeat(3, 4fr);
    gap: 1rem;
}

.hyperlink {
    color: rgb(0, 0, 241);
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    text-decoration: underline;
}

.botoes {
    display: flex;
    justify-content: space-evenly;

    .botao {
        min-width: 15vw;
    }
}

.botaoSair {
    background-color: #d62755;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #d62755, $amount: 5);
    }
}

.botaoAprovarComRessalva {
    background-color: #f7b547;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #f7b547, $amount: 5);
    }
}

.botaoSalvar {
    background-color: #4caf50;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #4caf50, $amount: 5);
    }
}

.botoesSuperiores {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
}

.titulo {
    text-align: center;
    margin: 2rem auto;
}
