@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.dados-wrapper {
    display: flex;
    gap: $spacing-2x;

    .highlight {
        color: $cor-acento-1;
        font-weight: bold;
    }

    .linha-tempo-wrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-2x;

        .etapas {
            display: flex;
            flex-direction: column;
            gap: $spacing-2x;
            padding: 0;

            .item {
                display: flex;
                align-items: center;
                gap: $spacing-2x;
                white-space: nowrap;

                svg {
                    height: 20px;
                    width: auto;

                    path {
                        fill: grey;
                    }
                }
                
                .confirmed {
                    path {
                        fill: green
                    }
                }
            }
        }

        .card-cancelamento {
            border: 2px solid red;
        }
    }

    .principal-wrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-2x;
        flex: 1;
        overflow-x: hidden;

        .enderecamento, .principal, .dados-parceiro {
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            overflow-x: auto;
        
            .item {
                padding: $spacing-2x;
                list-style: none;
            }
        }
        
        .documentos, .executores, .comunicacoes-parceiro, .alteracoes {
            max-height: 500px;
            overflow: hidden;

            >div {
                display: initial;
            }
        }
    }
}