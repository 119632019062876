.card {
    border: 1px solid #ddd;
    padding: 1rem;

    .options {
        margin: 1rem 0 2rem 0;
    }

    .alertMessage {
        color: red;
        margin: 2rem 0;
    }

    .buttonSubmit {
        margin: 2rem 0 1rem 0;
        text-align: center;
    }
}

.buttonClose {
    margin: 1rem 0;
    text-align: right;
}
