@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.filtrosAplicados {
    display: flex;
    align-items: center;
    font-size: 1rem;

    .prefix {
        font-weight: bold;
        margin-right: $spacing-3x;
    }

    .tag {
        padding: $spacing-1x $spacing-3x;
        margin: $spacing-1x;
        background-color: $cor-contraste-6;
        color: $cor-contraste-1;
        border: 1px solid $cor-contraste-1;
        border-radius: 5px;
        display: flex;
        align-items: center;

        .value {
            margin-left: $spacing-1x;
        }
    }

    .botaoFechar {
        cursor: pointer;
        margin-left: $spacing-2x;
    }
}
