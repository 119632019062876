@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.consulta-audiencias {
    > :not(:first-child) {
        margin-top: $spacing-3x;
    }

    .acao-audiencia {
        padding: $spacing-1x;
        border-radius: 3px;
        border: none;
        background-color: transparent;
        cursor: pointer;

        .icon {
            width: 20px;
            height: 20px;
            fill: $cor-acento-3;
        }

        &:disabled {
            cursor: default;
            .icon {
                fill: grey;
            }
        }

        &:hover:enabled {
            background-color: $cor-acento-3;

            .icon {
                fill: $cor-contraste-3;
            }
        }
    }

    .acao-audiencia-cancelar {
        .icon {
            path {
                fill: red;
            }
        }

        &:disabled {
            cursor: default;
            .icon {
                path {
                    fill: grey;
                }
            }
        }

        &:hover:enabled {
            background-color: red;

            .icon {
                path {
                    fill: $cor-contraste-3;
                }
            }
        }
    }

    .adicionar-audiencia {
        display: flex;
        justify-content: flex-end;

        a {
            outline: none;
            text-decoration: none;
            border: 2px solid $cor-acento-3;
            border-radius: 3px;
            padding: $spacing-2x $spacing-3x;
            background-color: $cor-contraste-3;
            color: $cor-acento-3;
            font-weight: bold;
            cursor: pointer;
            display: inline-block;

            &:hover {
                background-color: $cor-acento-3;
                color: $cor-contraste-3;
            }
        }
    }
}
