@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.detalhamento-anomalia {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing-2x;

    .cabecalho-processo {
        display: flex;
        gap: 1rem;
        > * {
            flex: 1;
        }
    }

    .anotacoes {
        display: flex;
        flex-direction: column;
        gap: $spacing-2x;
        align-items: stretch;

        textarea {
            resize: none;
            width: 100% !important;
            height: 80px !important;
        }
    }

    .acao-anomalia {
        .titulo-acao {
            display: flex;
            align-items: center;
            gap: $spacing-1x;

            .icone-acao-tomada {
                flex-shrink: 0;
                width: 24px;

                path {
                    fill: green;
                }
            }

            .icone-acao-nao-tomada {
                flex-shrink: 0;
                width: 24px;

                path {
                    fill: orange;
                }
            }

            .texto {
                .highlight {
                    color: $cor-acento-2;
                    font-weight: bold;
                }
            }
        }

        .campos-resolucao {
            display: flex;
            flex-direction: column;
            gap: $spacing-2x;

            .resolucao-arquivo {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: $spacing-1x;
            }

            .radio-group {
                display: flex;
                align-items: center;
                gap: $spacing-1x;
            }

            .no-margin {
                margin: 0;
            }
        }

        .acoes {
            display: flex;
            flex-wrap: wrap;
            gap: $spacing-1x;
        }

        .acao-coluna {
            margin-top: $spacing-2x;
        }

        .acao-linha {
            margin-left: $spacing-2x;
        }
    }
}
