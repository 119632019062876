@import "../../../../assets/styles/_dimensions";
@import "../../../../assets/styles/_colors";

.movimentacao-processo {
    padding: $spacing-6x;
    padding-right: 0;

    .titulo-destaque {
        display: flex;
        justify-content: space-between;

        .titulo-atividades {
            text-align: right;
            span {
                display: block;
                text-align: right;
                font-size: 0.8em;
                color: $cor-contraste-5;
            }
        }
    }

    .card-body-scrollable {
        max-height: 2000px;
        overflow: auto;
    }

    .timeline {
        width: 100%;
        position: relative;

        &-line {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .vertical-line {
                width: 1px;
                height: 100%;
                z-index: -1;
                background: #586571;
            }

            .hoje {
                background: #586571;
                border-radius: 100px;
                font-size: 10px;
                color: #ffffff;
                padding: 5px 10px;
            }
        }

        &-items {
            padding-top: 3ex;
            display: flex;
            flex-direction: column;

            .timeline-item {
                display: flex;
                flex-direction: row;

                .card-movimentacao {
                    margin-top: 2ex;
                    width: calc(50% - 20px);

                    border: 1px solid #dadada;
                    border-radius: 4px;
                    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.04);
                    background-color: #ffffff;

                    padding: $spacing-2x $spacing-3x;
                    position: relative;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        right: -6px;
                        top: 12px;
                        width: 10px;
                        height: 10px;
                        background-image: linear-gradient(45deg, transparent 49%, white 50%);
                        transform: rotate(45deg);
                        border-top: solid 1px #dadada;
                        border-right: solid 1px #dadada;
                    }

                    .bolinha-timeline {
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        right: -29px;
                        top: 9px;
                        border-radius: 50%;

                        background: #2e343b;
                        border: 4px solid #27ae60;
                        box-sizing: border-box;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.108696);
                    }

                    .movimentacao-data {
                        font-size: 12px;
                        line-height: 24px;
                    }
                    .movimentacao-titulo {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        font-family: "Gibson Semibold";
                        word-wrap: break-word;
                    }
                    .movimentacao-subtitulo {
                        font-size: 14px;
                        line-height: 14px;
                        margin-top: $spacing-1x;
                        min-height: $spacing-8x;
                    }

                    .movimentacao-rotulos {
                        margin: 0.7rem 0;
                    }
                }

                &.atividade {
                    flex-direction: row-reverse;

                    .card-movimentacao {
                        &::before {
                            left: -6px;
                            transform: rotate(-135deg);
                        }

                        .bolinha-timeline {
                            left: -29px;
                        }
                    }
                }
            }
        }
    }
}
