@import "../../assets/styles/_colors";
@import "../../assets/styles/_dimensions";

.sumario-processos {
    > div {
        display: flex;

        .item {
            // flex: 1;
            display: flex;
            min-width: 230px;
            > img {
                padding-left: $spacing-4x;
            }
        }
    }

    .area {
        border-bottom: 2px solid $cor-contraste-6;

        .item {
            flex-direction: column;
            align-items: center;
            padding: $spacing-4x;
            border-bottom: 2px solid transparent;
            margin-bottom: -2px;

            &.active,
            &:hover {
                border-bottom-color: $cor-acento-2;
                cursor: pointer;
            }

            .rotulo {
                text-transform: uppercase;
                margin-bottom: $spacing-1x;
            }

            .valor {
                font-weight: bold;
                color: $cor-contraste-5;
            }
        }
    }

    .rito {
        background-color: $cor-contraste-6;

        .item {
            justify-content: center;
            align-items: center;

            .link-rito {
                flex: 1;
                padding: $spacing-4x 0;
                justify-content: center;
            }
            .dropdown-rito,
            .link-rito {
                display: flex;
                align-items: center;
                user-select: none;
                &.active,
                &:hover {
                    background-color: $cor-contraste-3;
                    cursor: pointer;
                }
            }
            .dropdown-rito {
                padding: 0 $spacing-3x;
                height: 100%;
            }

            .icone {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                padding: $spacing-2x;

                img {
                    width: 15px;
                }
            }

            .rotulo {
                margin: 0 $spacing-2x;
                text-transform: uppercase;
                font-weight: bold;
            }

            .valor {
                font-weight: bold;
            }
        }
    }

    .fases {
        .fase {
            padding: $spacing-2x;
            flex: 1;

            .header {
                padding: $spacing-2x;

                &:hover {
                    background-color: $cor-contraste-2;
                    cursor: pointer;
                }

                .rotulo {
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-right: $spacing-2x;
                }

                .valor {
                    font-weight: bold;
                }
            }

            .momento {
                border-top: 1px solid $cor-contraste-2;
                padding: $spacing-4x;
                display: flex;

                &:hover {
                    background-color: $cor-contraste-2;
                    cursor: pointer;
                }

                .valor {
                    font-weight: bold;
                    margin-right: $spacing-2x;
                }
            }
        }
    }
    .link {
        color: inherit;
        text-decoration: none;
    }
}
