@import "../../../assets/styles/_dimensions";

.linhaFlex {
    display: flex;
    flex-wrap: wrap;
    > div {
        flex: 1;
    }
    > :first-child {
        padding-right: $spacing-6x;
    }
}
