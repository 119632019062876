@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.cardFiltro {
    margin-bottom: $spacing-4x;
}

.gridForm {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing-3x;
}

.containerFlex {
    display: flex;

    .moduloWrapper {
        flex: 1;
    }

    .usuariosWrapper {
        flex: 2;
        margin-left: $spacing-4x;
    }
}

.formAddUsuario {
    text-align: left;

    .fields {
        height: 400px;
    }
}

.buttons {
    text-align: right;
    border-top: 1px solid cor-contraste-2;
}

.treeInline {
    ol ol {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.subtitle {
    margin-left: $spacing-3x;
    color: $cor-contraste-5;
}
