@import "../../../../assets/styles/_dimensions";
@import "../../../../assets/styles/_colors";

.cabecalho-processo {
    .card {
        padding-top: $spacing-4x;
        padding-bottom: $spacing-4x;
        .header {
            margin-bottom: $spacing-4x;
            .icon {
                width: 40px;
                height: 40px;
                img {
                    transform: scale(0.6667);
                }
            }
        }
        .title {
            font-weight: 600;
            font-size: 16px;
        }
        hr {
            height: 0;
            border: 1px solid $cor-contraste-6;
            margin-left: -$spacing-6x;
            margin-right: -$spacing-6x;
            margin-bottom: 0;
        }
    }

    .linha {
        display: flex;
        flex-flow: row wrap;
        .campo {
            flex: 1;
        }
    }

    .campo {
        padding-top: $spacing-3x;
        padding-bottom: $spacing-3x;
        border-bottom: 1px solid $cor-contraste-6;
        .chave {
            font-weight: 600;
            font-size: 14px;
            display: inline-block;
            width: 10em;
        }
        .valor {
            display: inline-block;
        }
    }

    .linha:last-child {
        .campo {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

.classe-processo {
    font-weight: 400;
    font-size: 1.1rem;
}
