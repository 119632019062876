@import "node_modules/react-checkbox-tree/src/scss/react-checkbox-tree.scss";
@import "../../../assets/styles/_colors";

.react-checkbox-tree {
    label {
        font-size: 0.9em;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .rct-collapse {
        &.rct-collapse-btn {
            transform: translateY(-5px);
        }
    }

    .rct-node {
        .rct-node {
            margin-left: 20px;
        }
    }

    .rct-checkbox {
        color: #ccc;

        &[aria-checked="true"] {
            color: $cor-acento-2;
        }
    }

    .indeterminate {
        color: $cor-acento-2;
    }
}
