@import "../../assets/styles/colors";
@import "../../assets/styles/dimensions";

.dashboard {
    > :not(:first-child) {
        margin-top: $spacing-2x;
    }

    .linhaFlex {
        display: flex;
        flex-wrap: wrap;

        > :not(:first-child) {
            margin-left: $spacing-2x;
        }
        > div {
            margin-top: $spacing-2x;
            flex: 1;
        }
    }
}
