@import "../../../assets/styles/_dimensions";

.fileName {
    display: flex;
}
.clearButton {
    cursor: pointer;
    background: unset;
    border: none;
}
.loadFileLabel {
    display: flex;
    padding-top: $spacing-1x;
    cursor: pointer;
}
.loadFileIcon {
    margin-left: 0.3em;
    width: 1.3em;
    height: 1.3em;
}
