@import "../../../assets/styles/_colors";
@import "../../../assets/styles/_dimensions";

.cardFiltro {
    margin-bottom: $spacing-4x;
}

.gridForm {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing-3x;
}

.itemAdd {
    max-height: 36px;
    align-self: end;
    margin-bottom: 4px;
}

.info {
    line-height: 120%;
    margin: 32px 0;
    margin-left: 32px;
    color: #757575;
}

.actionButton {
    outline: none;
    background-color: transparent;
    border: none;
    border-radius: 3px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: #6758f3;
    }
}

.search {
    display: flex;
    flex-direction: row;

    button {
        border: none;
        border-radius: 3px;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        padding: 5px;

        align-self: flex-end;

        svg {
            width: 20px;
            height: auto;
        }

        &:hover {
            color: #fff;
            background-color: #6758f3;
        }
    }
}
