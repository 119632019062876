@import "../../assets/styles/_colors";
@import "../../assets/styles/_dimensions";

.field-control {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .label {
        margin-bottom: $spacing-1x;
    }

    .input {
        border: none;
        border-bottom: 1px solid $cor-contraste-5;
        outline: none;
        padding: $spacing-2x;
        width: 100%;
        height: 3em;

        &:disabled {
            border: none;
        }
    }

    .invisible {
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    .field-wrapper {
        display: flex;
        width: 100%;
    }

    .toggle-wrapper {
        display: flex;
        align-items: center;
        width: auto;

        .toggle {
            margin: $spacing-1x;
        }
    }

    .error {
        color: red;
        padding: 0 $spacing-2x;
        margin: 0;
    }
    .mesDatePicker {
        text-transform: capitalize;
    }
    .range {
        flex: 1;

        > .content {
            display: flex;

            .prefix {
                background-color: $cor-contraste-2;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $cor-contraste-5;

                &.prefix-error {
                    border-color: red;
                }
            }

            > .input {
                margin: 0;
            }
        }
    }
}
