@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/dimensions";

.abrir-prestador-profile {
    background-color: transparent;
    border: none;
    padding: $spacing-1x;
    text-decoration: underline;
    outline: none;
    cursor: pointer;

    &:hover {
        background-color: $cor-contraste-4;
    }
}

.alert-prestador-profile {
    header {
        display: flex;
        padding: $spacing-4x;

        .foto {
            background-color: $cor-contraste-5;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $cor-contraste-3;
            text-transform: uppercase;
            max-width: 142px;
            overflow: hidden;

            img {
                height: 80px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 $spacing-4x;
            text-align: start;

            .nome {
                font-weight: bold;
                margin-bottom: $spacing-1x;
            }
        }

        .rank {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-transform: uppercase;
            text-align: start;
            margin-left: auto;

            .rotulo {
                font-size: 0.8em;
            }

            .value-wrapper {
                display: flex;
                align-items: center;
                margin-top: $spacing-1x;

                .value {
                    font-weight: bold;
                    font-size: 1.5em;
                    margin-right: $spacing-1x;
                }
            }
        }
    }

    .biografia {
        padding: $spacing-4x;
        text-align: start;

        .titulo {
            font-weight: bold;
            font-size: 1.2em;
        }
    }
}
