@import "../../../../assets/styles/_dimensions";

.datas-politica {
    font-size: 12px;
    color: #6b6c6f;
}

.datas-politica,
.estado-proposta {
    margin-top: $spacing-1x;
    line-height: $spacing-5x;
}

.nome-politica,
.valor-proposta {
    font-size: 14px;
    font-family: "Gibson Semibold";
}
