@import "../../../../assets/styles/_colors";
@import "../../../../assets/styles/_dimensions";

.card-processo-detalhe {
    margin-top: $spacing-4x;
    border: 1px solid $cor-contraste-6;
    border-radius: 4px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.04);
    background-color: $cor-contraste-3;

    button {
        border: 0;
        padding: 0;
        background: inherit;
        cursor: pointer;
    }

    > header {
        padding: $spacing-5x;
        display: flex;
        justify-content: space-between;

        h3 {
            margin: 0;
            font-size: 16px;
            font-family: "Gibson Semibold";
        }
    }

    > main {
        padding: $spacing-5x;
    }

    .card-body-scrollable {
        max-height: 400px;
        overflow: auto;
    }

    .card-body-scrollable > table,
    > table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            padding: $spacing-4x $spacing-4x;
        }

        thead {
            th {
                background: $cor-contraste-7;
                font-family: "Gibson Semibold";
                text-align: left;
            }
        }
        tbody {
            overflow: auto;

            tr {
                border-top: 1px solid $cor-contraste-6;
            }

            .col-download {
                text-align: right;
            }
        }
    }
}

.center {
    text-align: center;
}
