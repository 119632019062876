@import "~@omnijus/common/build/assets/styles/_colors";
@import "~@omnijus/common/build/assets/styles/_dimensions";

.atualizacaoProcesso {
    > :not(:first-child) {
        margin-top: $spacing-3x;
    }
}

.cabecalho {
    display: flex;
    gap: 1rem;
    > * {
        flex: 1;
    }
}

.modalIncluirDocumentos {
    > * {
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
}

.alignRight {
    display: flex;
    justify-content: flex-end;
}

.marginRight {
    margin-right: 1rem;
}

.botaoSalvar {
    background-color: #4caf50;
    border-radius: 5px;
    padding: $spacing-3x;
    border: none;
    color: $cor-contraste-3;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: lighten($color: #4caf50, $amount: 5);
    }
}

.marginTop {
    margin-top: 1rem;
}

.formIncluirDocumentos {
    display: grid;
    gap: 1rem;
    grid-template-columns: 7fr 5fr;
}

.trashIcon {
    path {
        fill: red;
    }
}

.formLinha1 {
    display: grid;
    grid-template-columns: 3fr 4.5fr 4.5fr;
    gap: 1rem;
}

.formLinha2 {
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    gap: 1rem;
}
