@import "../../../assets/styles/colors";
@import "../../../assets/styles/dimensions";

.exportar-csv {
    border: none;
    padding: $spacing-2x;
    background-color: $cor-acento-6;
    color: $cor-contraste-3;
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.6em;
    outline: none;

    &:hover {
        background-color: lighten($color: $cor-acento-6, $amount: 5);
    }

    .texto {
        margin-left: $spacing-2x;
    }
}
