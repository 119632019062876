@import "../../../../assets/styles/_colors";
@import "../../../../assets/styles/_dimensions";

.audienciaCancelada {
    color: $cor-acento-5;
}

.downloadAta {
    cursor: pointer;
}

.localAudiencia {
    .overflowEllipsis {
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: $spacing-1x;
    }

    svg {
        height: 16px;
    }
}
