@import "../../../assets/styles/_colors";

.input-wrapper {
    display: flex;
    align-items: stretch;

    .acao {
        border: none;
        border-radius: 3px;
        background-color: transparent;
        cursor: pointer;
        outline: none;

        &:hover{
            background-color: $cor-contraste-2;
        }

        svg {
            width: 20px;
            height: auto;
        }
    }
}